import { Typography, Collapse, Card, Space, Radio } from "antd";
import "./ItemFoodQualityIssue.less";
import { useReducer, useState } from "react";
import CancelledRequestMessage from "../Shared/CancelledRequestMessage";
import SelectableList from "../Shared/List/SelectableList";
import SelectableListItem from "../Shared/List/ListItem/SelectableListItem";
import ResolutionList from "../Shared/List/ResolutionList";
import ResolutionListItem from "../Shared/List/ListItem/ResolutionListItem";
import ClearItemsButtonWithPopconfirm from "../Shared/Buttons/ClearItemsButtonWithPopconfirm";
import CancelButtonWithPopconfirm from "../Shared/Buttons/CancelButtonWithPopconfirm";
import SubmitButtonWithPopconfirm from "../Shared/Buttons/SubmitButtonWithPopconfirm";
import DropDownSelectBox from "../Shared/DropDownSelectBox/DropDownSelectBox";
import TextArea from "antd/lib/input/TextArea";
import ReshipmentAtNoCost from "../Shared/Footers/ReshipmentAtNoCost";
import EstimatedRefundOriginalPayment from "../Shared/Footers/EstimatedRefundOriginalPayment";
import {
    ItemFoodQualityIssueBaseProps,
    ItemInfo,
    ResolutionItemInfo,
    ResolutionsInfo,
} from "./FoodQualityIssueTypes";
import { CustomDialogCommand } from "@Models/chatBot/CustomDialogCommand";
import {
    configureInitialResolutionTypeState,
    resolutionTypeRadioButtonReducer,
    ResolutionTypeReducerActionEnum,
    ResolutionTypesEnum,
} from "../Shared/ResolutionTypeRadioButtonReducer";
import EstimatedRefundStoreCredit from "../Shared/Footers/EstimatedRefundStoreCredit";
import ParentheticalText from "../Shared/Text/ParentheticalText";

const { Text } = Typography;
const { Panel } = Collapse;

interface ItemFoodQualityIssueProps extends ItemFoodQualityIssueBaseProps {
    isCancelClicked?: boolean;
    isReadOnly: boolean;
    webchatSendMessageBack?: any;
    dboChatId?: number;
}

const ItemSelectedWithZeroQuantityAlert = (): React.JSX.Element => {
    return (
        <Card className="ant-card-error">
            <Text>Please enter the quantity of the item above.</Text>
        </Card>
    );
};

const IssueTypeDisplay = ({
    issueTypeMessage,
}: {
    issueTypeMessage: string;
}): React.JSX.Element => {
    return (
        <Text
            className="food-quality-issue-dialog-details-type-message"
            data-testid="food-quality-issue-dialog-details-type-message"
        >
            {issueTypeMessage}
        </Text>
    );
};

const ItemFoodQualityIssue = ({
    isCancelClicked,
    isReadOnly,
    orderInfo,
    resolutionsInfo,
    webchatSendMessageBack,
    eligibleFoodQualityItemIssueNames,
    messageId,
    chatId,
    dboChatId,
}: ItemFoodQualityIssueProps): React.JSX.Element => {
    const commentMaxLength = 100;

    const [resolutionsList, setResolutionsList] = useState<
        ResolutionItemInfo[]
    >(
        resolutionsInfo?.resolutionItems?.length > 0
            ? resolutionsInfo.resolutionItems
            : []
    );

    const totalPrice = resolutionsList.reduce((total, item) => {
        return total + item.resolutionQuantity * item.price;
    }, 0);

    const [resolutionInfoComment, setResolutionInfoComment] = useState<string>(
        resolutionsInfo.resolutionItems[0]?.comment ?? ""
    );

    const [itemIssueName, setItemIssueName] = useState<string>(
        resolutionsInfo.itemIssueName ?? ""
    );

    const [itemList, setItemList] = useState<ItemInfo[]>(
        orderInfo.items.map((item) => ({
            ...item,
            isChecked: false,
            resolutionQuantity: 0,
        }))
    );

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formButtonClicked, setFormButtonClicked] = useState<string | null>(
        null
    );

    const [resolutionTypeState, resolutionTypeDispatch] = useReducer(
        resolutionTypeRadioButtonReducer,
        configureInitialResolutionTypeState(resolutionsInfo)
    );

    const isRequestedResolutionValid = (resolution: string): boolean => {
        return itemList.some(
            (item: ItemInfo) =>
                item.resolutions.some(
                    (eligibleResolution: string) =>
                        eligibleResolution === resolution
                ) && item.resolutionQuantity > 0
        );
    };

    const isPaymentCreditValid = isRequestedResolutionValid(
        ResolutionTypesEnum.PAYMENT_CREDIT
    );

    const isStoreCreditValid = isRequestedResolutionValid(
        ResolutionTypesEnum.STORE_CREDIT
    );

    const isReshipValid = isRequestedResolutionValid(
        ResolutionTypesEnum.RESHIPMENT
    );

    if (
        resolutionsInfo?.resolutionItems?.length > 0 &&
        resolutionsList.length === 0
    ) {
        setResolutionsList(resolutionsInfo.resolutionItems);
        setResolutionInfoComment(resolutionsInfo.resolutionItems[0]?.comment);
        setItemIssueName(resolutionsInfo.itemIssueName);

        resolutionTypeDispatch({
            type: ResolutionTypeReducerActionEnum.SELECT_RESOLUTION_TYPE,
            payload: {
                resolutionType: resolutionsInfo.resolutionTypeName,
                isPaymentCreditValid,
                isStoreCreditValid,
                isReshipValid,
            },
        });
    }

    const resolutionName = (): ResolutionTypesEnum | undefined => {
        switch (true) {
            case resolutionTypeState.resolutionTypes.reshipment:
                return ResolutionTypesEnum.RESHIPMENT;
            case resolutionTypeState.resolutionTypes.storeCredit:
                return ResolutionTypesEnum.STORE_CREDIT;
            case resolutionTypeState.resolutionTypes.originalPayment:
                return ResolutionTypesEnum.PAYMENT_CREDIT;
            default:
                return undefined;
        }
    };

    const ReadOnlyFooterDetail = (): React.JSX.Element => {
        const issueTypeMessage = resolutionInfoComment
            ? `${itemIssueName} - ${resolutionInfoComment}`
            : itemIssueName;

        switch (resolutionName()) {
            case ResolutionTypesEnum.RESHIPMENT:
                return (
                    <div className="food-quality-issue-dialog-details">
                        <IssueTypeDisplay issueTypeMessage={issueTypeMessage} />
                        <br />

                        <ReshipmentAtNoCost />
                    </div>
                );
            case ResolutionTypesEnum.STORE_CREDIT:
                return (
                    <div className="food-quality-issue-dialog-details">
                        <EstimatedRefundStoreCredit totalPrice={totalPrice} />
                        <br />
                        <IssueTypeDisplay issueTypeMessage={issueTypeMessage} />
                    </div>
                );
            case ResolutionTypesEnum.PAYMENT_CREDIT:
                return (
                    <div className="food-quality-issue-dialog-details">
                        <EstimatedRefundOriginalPayment
                            totalPrice={totalPrice}
                        />
                        <br />
                        <IssueTypeDisplay issueTypeMessage={issueTypeMessage} />
                    </div>
                );
            default:
                return <></>;
        }
    };

    const showSelectResolutionItemsErrorCard = (
        <Card
            className={`${
                resolutionsList.length === 0 &&
                formButtonClicked !== null &&
                "ant-card-error"
            }`}
        >
            {!isReadOnly && (
                <Text>Please select items above to enter a resolution.</Text>
            )}
        </Card>
    );

    const hasCheckedItemWithZeroQuantity = (): boolean => {
        return itemList.some(
            (item) => item.isChecked && item.resolutionQuantity === 0
        );
    };

    const ItemListRenderItem = (item: ItemInfo): React.JSX.Element => {
        const QuantityHeader = (
            <>
                Please enter the quantity of the item that has issues{" "}
                <ParentheticalText text={"Required"} />
            </>
        );
        const QuantityWarningUnitsPerPackagingEqualOne =
            "Please enter the quantity of the item that has issues.";
        const QuantityWarningUnitsPerPackagingGreaterThanOne =
            "Please enter a quantity at or below  quantity purchased.";
        return (
            <SelectableListItem
                key={item.clarkitbizOrderItemId}
                item={item}
                resolutionList={resolutionsList}
                setResolutionList={setResolutionsList}
                itemList={itemList}
                setItemList={setItemList}
                setFormButtonClicked={setFormButtonClicked}
                readOnly={isReadOnly}
                isSubmitting={isSubmitting}
                quantityHeader={QuantityHeader}
                quantityWarningTextUnitsPerPackagingEqualsOne={
                    QuantityWarningUnitsPerPackagingEqualOne
                }
                quantityWarningTextUnitsPerPackagingGreaterThanOne={
                    QuantityWarningUnitsPerPackagingGreaterThanOne
                }
                allowIndividualItems={false}
                data-testid="food-quality-issue-selectable-list-item"
            />
        );
    };

    const ResolutionsListRenderItem = (
        item: ItemInfo,
        index: number
    ): React.JSX.Element => {
        return (
            <ResolutionListItem
                resolutionList={resolutionsList}
                itemList={itemList}
                item={item}
                index={index}
                costPerItem={item.price}
                isReadOnly={isReadOnly}
                isSubmitting={isSubmitting}
                setResolutionList={setResolutionsList}
                test-dataid="refund-reship-dialog-resolution-list-item"
            />
        );
    };

    const ScreenReaderOnlySpan = (): React.JSX.Element => {
        return (
            <span className="sr-only" aria-live="polite" aria-atomic="true">
                {`${resolutionsList[0].comment} of ${commentMaxLength} characters used`}
            </span>
        );
    };

    const resolutionTypeOnClickHandler = (
        resolutionType: ResolutionTypesEnum
    ): void => {
        resolutionTypeDispatch({
            type: ResolutionTypeReducerActionEnum.SELECT_RESOLUTION_TYPE,
            payload: {
                resolutionType: resolutionType,
                isPaymentCreditValid,
                isStoreCreditValid,
                isReshipValid,
            },
        });
    };

    const reshipRadioButton = (
        <Radio
            value="reshipment"
            onClick={(): void =>
                resolutionTypeOnClickHandler(ResolutionTypesEnum.RESHIPMENT)
            }
            checked={resolutionTypeState.resolutionTypes.reshipment}
            disabled={isSubmitting}
        >
            Reshipment
        </Radio>
    );

    const originalPaymentRadioButton = (
        <Radio
            value="originalPayment"
            onClick={(): void =>
                resolutionTypeOnClickHandler(ResolutionTypesEnum.PAYMENT_CREDIT)
            }
            checked={resolutionTypeState.resolutionTypes.originalPayment}
            disabled={isSubmitting}
        >
            Original Payment
        </Radio>
    );

    const storeCreditRadioButton = (
        <Radio
            value="storeCredit"
            onClick={(): void =>
                resolutionTypeOnClickHandler(ResolutionTypesEnum.STORE_CREDIT)
            }
            checked={resolutionTypeState.resolutionTypes.storeCredit}
            disabled={isSubmitting}
        >
            Store Credit
        </Radio>
    );

    const refundRadioButton = (
        <Radio
            value="refund"
            onClick={(): void =>
                resolutionTypeOnClickHandler(ResolutionTypesEnum.REFUND)
            }
            checked={resolutionTypeState.resolutionTypes.refund}
            disabled={isSubmitting}
        >
            Refund
        </Radio>
    );

    const showRefundTypeRadioButtons = (
        <Space direction="vertical" size={4} style={{ marginLeft: "16px" }}>
            {isStoreCreditValid && storeCreditRadioButton}
            {isPaymentCreditValid && originalPaymentRadioButton}
        </Space>
    );

    const showResolutionRadioButtons = (
        <Space direction="vertical" size={4} style={{ margin: "8px 0 12px" }}>
            {(isPaymentCreditValid || isStoreCreditValid) && refundRadioButton}
            {resolutionTypeState.resolutionTypes.refund === true &&
                showRefundTypeRadioButtons}
            {isReshipValid && reshipRadioButton}
        </Space>
    );

    const shouldShowResolutionSelectionMessage =
        !isReadOnly &&
        !resolutionTypeState.resolutionTypeSelected &&
        formButtonClicked === "submit" &&
        resolutionsList.length > 0;

    const shouldShowRefundSelectionMessage =
        !isReadOnly &&
        formButtonClicked === "submit" &&
        resolutionsList.length > 0 &&
        resolutionTypeState.resolutionTypes.refund === true &&
        resolutionTypeState.resolutionTypes.storeCredit === false &&
        resolutionTypeState.resolutionTypes.originalPayment === false;

    const shouldShowZeroQuantityAlert =
        !isReadOnly &&
        formButtonClicked === "submit" &&
        hasCheckedItemWithZeroQuantity() &&
        resolutionsList.length > 0;

    const clearItemsButtonPopconfirmOnConfirmHandler = (): void => {
        const updatedList: ItemInfo[] = itemList.map((listItem) => {
            return {
                ...listItem,
                isChecked: false,
                resolutionQuantity: 0,
            };
        });

        resolutionTypeDispatch({
            type: ResolutionTypeReducerActionEnum.RESET,
        });

        setFormButtonClicked(null);

        setItemList([...updatedList]);
        setResolutionsList([]);

        setResolutionInfoComment("");
        setItemIssueName("");
    };

    const clearItemsButtonOnClickHandler = (): void => {
        if (
            resolutionsList.length === 0 &&
            !itemList.find((item) => item.isChecked === true)
        ) {
            setFormButtonClicked("clear");
        }
    };

    const CancelCommand =
        CustomDialogCommand.CustomDialogCommandType
            .FoodQualityIssueCancelCommand;
    const SubmitCommand =
        CustomDialogCommand.CustomDialogCommandType
            .FoodQualityIssueSubmitCommand;

    const cancelButtonPopconfirmOnConfirmHandler = (): void => {
        webchatSendMessageBack(
            {
                command: CancelCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                    dboChatId: dboChatId,
                },
            },
            ""
        );
    };

    const submitButtonOnClickHandler = (): void => {
        if (
            resolutionsList.length === 0 ||
            !resolutionTypeState.resolutionTypeSelected ||
            (resolutionTypeState.resolutionTypes.refund === true &&
                resolutionTypeState.resolutionTypes.storeCredit === false &&
                resolutionTypeState.resolutionTypes.originalPayment ===
                    false) ||
            eligibleFoodQualityItemIssueNames.find(
                (eligibleItem: string) => eligibleItem === itemIssueName
            ) === undefined ||
            resolutionInfoComment === "" ||
            resolutionInfoComment === undefined ||
            hasCheckedItemWithZeroQuantity()
        ) {
            setFormButtonClicked("submit");
        }
    };

    const submitButtonPopconfirmOnConfirmHandler = (): void => {
        const newItems: ResolutionItemInfo[] = resolutionsList.map(
            (item: ResolutionItemInfo): ResolutionItemInfo => {
                return {
                    ...item,
                    quantity: item.resolutionQuantity,
                    resolutionPrice: item.price,
                    comment: resolutionInfoComment,
                };
            }
        );

        const resolution: ResolutionsInfo = {
            orderNumber: orderInfo.orderNumber,
            resolutionTypeName: resolutionName(),
            resolutionItems: newItems,
            itemIssueName: itemIssueName,
            relatedChatId: dboChatId,
        };

        setIsSubmitting(true);
        setFormButtonClicked(null);

        webchatSendMessageBack(
            {
                command: SubmitCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                    resolution: resolution,
                    dboChatId: dboChatId,
                },
            },
            ""
        );
    };

    const hasValidItemIssuesName =
        eligibleFoodQualityItemIssueNames.includes(itemIssueName);

    const isSubmitButtonPopconfirmDisabled =
        isSubmitting ||
        resolutionsList.length === 0 ||
        !resolutionTypeState.resolutionTypeSelected ||
        (resolutionTypeState.resolutionTypes.refund &&
            !resolutionTypeState.resolutionTypes.storeCredit &&
            !resolutionTypeState.resolutionTypes.originalPayment) ||
        resolutionInfoComment.length < 1 ||
        !hasValidItemIssuesName ||
        hasCheckedItemWithZeroQuantity();

    const FoodQualityIssueUI = (): React.JSX.Element => {
        return (
            <Space
                size={0}
                className="item-food-quality-issue"
                data-testid="item-food-quality-issue"
            >
                {!isReadOnly && (
                    <Text>
                        Below is the list of items from your order. Please check
                        the item(s) below, review, and submit your resolution.
                    </Text>
                )}
                <Text
                    strong
                    style={{ margin: "16px 0", display: "inline-block" }}
                >
                    {`Order # ${orderInfo.orderNumber}`}
                </Text>
                {!isReadOnly && (
                    <Collapse
                        defaultActiveKey={["1"]}
                        className="item-food-quality-issue__collapse"
                    >
                        <Panel
                            header={`${itemList.length} Item(s) in Order`}
                            key="1"
                        >
                            <SelectableList
                                itemList={itemList}
                                itemListRenderItem={ItemListRenderItem}
                            />
                        </Panel>
                    </Collapse>
                )}
                {(!isReadOnly || resolutionsList.length > 0) && (
                    <Card
                        className="selected-items"
                        data-testid="food-quality-resolution-selected-items"
                    >
                        <Text strong>Items & Preferred Resolutions</Text>
                        {resolutionsList.length < 1 ? (
                            !isReadOnly && showSelectResolutionItemsErrorCard
                        ) : (
                            <ResolutionList
                                resolutionList={resolutionsList}
                                totalPrice={totalPrice}
                                isReadOnly={isReadOnly}
                                readOnlyFooterDetail={<ReadOnlyFooterDetail />}
                                resolutionListRenderItem={
                                    ResolutionsListRenderItem
                                }
                            />
                        )}
                        {shouldShowZeroQuantityAlert && (
                            <ItemSelectedWithZeroQuantityAlert />
                        )}
                        {!isReadOnly && resolutionsList.length > 0 && (
                            <div className="food-quality-issue-dialog">
                                <DropDownSelectBox
                                    className="food-quality-issue-dialog-type"
                                    label={
                                        <>
                                            Food product quality issue.{" "}
                                            <ParentheticalText
                                                text={"Required"}
                                            />
                                        </>
                                    }
                                    options={eligibleFoodQualityItemIssueNames.map(
                                        (eligibleIssue: string) => ({
                                            value: eligibleIssue,
                                            label: eligibleIssue,
                                        })
                                    )}
                                    value={itemIssueName}
                                    onChange={(value): void =>
                                        setItemIssueName(value)
                                    }
                                    placeholder="Select the food quality issue"
                                    data-testid="food-quality-issue-dialog-type-dropdown"
                                    isReadOnly={isReadOnly}
                                    isSubmitting={isSubmitting}
                                    validate={(): boolean => {
                                        return !(
                                            resolutionsList.length > 0 &&
                                            eligibleFoodQualityItemIssueNames.find(
                                                (eligibleIssue) =>
                                                    itemIssueName ===
                                                    eligibleIssue
                                            ) === undefined &&
                                            formButtonClicked === "submit"
                                        );
                                    }}
                                    validationFailedMessage="Please select a Food Product Quality Issue."
                                />
                                <div
                                    data-testid="food-quality-issue-dialog-comment-container"
                                    className="food-quality-issue-dialog-comment-container"
                                >
                                    <Text data-testid="food-quality-issue-dialog-comment-text">
                                        Additional details of the issue.{" "}
                                        <ParentheticalText text={"Required"} />
                                    </Text>
                                    <TextArea
                                        placeholder="Add any additional details of the issue."
                                        maxLength={commentMaxLength}
                                        data-testid="food-quality-issue-dialog-comment-input"
                                        showCount
                                        value={resolutionInfoComment}
                                        onChange={(text): void => {
                                            setResolutionInfoComment(
                                                text.target.value
                                            );
                                        }}
                                        disabled={isSubmitting}
                                    />
                                    {ScreenReaderOnlySpan()}
                                    {(resolutionInfoComment === "" ||
                                        resolutionInfoComment === undefined) &&
                                        formButtonClicked === "submit" && (
                                            <Text
                                                type="danger"
                                                style={{ marginBottom: "12px" }}
                                                aria-live="polite"
                                                data-testid="food-quality-issue-dialog-comment-missing-error"
                                            >
                                                Please add details of the issue.
                                            </Text>
                                        )}
                                    {resolutionInfoComment?.length >=
                                        commentMaxLength && (
                                        <Text
                                            type="danger"
                                            style={{ marginBottom: "12px" }}
                                            aria-live="assertive"
                                            data-testid="food-quality-issue-dialog-comment-length-error"
                                        >
                                            {`Please limit character count to ${commentMaxLength}.`}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        )}
                        {!isReadOnly && (
                            <>
                                <Text style={{ display: "block" }}>
                                    Select your resolution preference.{" "}
                                    <ParentheticalText text={"Required"} />
                                </Text>
                                {showResolutionRadioButtons}
                            </>
                        )}
                        {shouldShowResolutionSelectionMessage && (
                            <Text
                                type="danger"
                                style={{ marginBottom: "12px" }}
                            >
                                Please select your resolution preference.
                            </Text>
                        )}
                        {shouldShowRefundSelectionMessage && (
                            <Text
                                type="danger"
                                style={{ marginBottom: "12px" }}
                                aria-live="polite"
                            >
                                Please select Store Credit or Original payment
                                for your Refund.
                            </Text>
                        )}
                        {!isReadOnly && (
                            <>
                                <ClearItemsButtonWithPopconfirm
                                    isSubmitting={isSubmitting}
                                    clearItemsButtonPopconfirmOnConfirmHandler={
                                        clearItemsButtonPopconfirmOnConfirmHandler
                                    }
                                    clearItemsButtonOnClickHandler={
                                        clearItemsButtonOnClickHandler
                                    }
                                    itemList={itemList}
                                    isReadOnly={isReadOnly}
                                    data-testId="food-quality-issue-dialog-clear-items-button"
                                />
                                <CancelButtonWithPopconfirm
                                    isSubmitting={isSubmitting}
                                    cancelButtonPopconfirmOnConfirmHandler={
                                        cancelButtonPopconfirmOnConfirmHandler
                                    }
                                    data-testId="food-quality-issue-dialog-cancel-button"
                                />
                                <SubmitButtonWithPopconfirm
                                    isSubmitting={isSubmitting}
                                    isSubmitButtonPopconfirmDisabled={
                                        isSubmitButtonPopconfirmDisabled
                                    }
                                    submitButtonPopconfirmOnConfirmHandler={
                                        submitButtonPopconfirmOnConfirmHandler
                                    }
                                    submitButtonOnClickHandler={
                                        submitButtonOnClickHandler
                                    }
                                    data-testId="food-quality-issue-dialog-submit-button"
                                />
                            </>
                        )}
                    </Card>
                )}
            </Space>
        );
    };

    return (
        <div
            className="item-issue-food-quality item-issue-dialog"
            data-testid="food-quality-issue-dialog"
        >
            {isCancelClicked ? (
                <CancelledRequestMessage data-testid="food-quality-issue-dialog-cancelled-request-message" />
            ) : (
                FoodQualityIssueUI()
            )}
        </div>
    );
};

export default ItemFoodQualityIssue;
