import React from "react";
import { List } from "antd";
import "./SelectableList.less";

const SelectableList = (props: any): React.JSX.Element => {
    const { itemList, itemListRenderItem } = props;

    return (
        <List
            className="item-issues__list"
            itemLayout="vertical"
            pagination={{
                showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                pageSize: 5,
                total: itemList.length,
                size: "small",
                showSizeChanger: false,
            }}
            dataSource={itemList}
            renderItem={itemListRenderItem}
        />
    );
};

export default SelectableList;
