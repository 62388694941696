import { Space, Typography } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const { Text } = Typography;

interface OperatorOnlyInformationalChatBubbleProps {
    text: string;
}

const OperatorOnlyInformationalChatBubble = ({
    text,
}: OperatorOnlyInformationalChatBubbleProps): JSX.Element => {
    return (
        <Space
            className="chat-bubble operator-only-informational"
            direction="vertical"
            size={0}
        >
            <Space
                className="operator-only-informational__header"
                direction="horizontal"
                size={0}
            >
                <ExclamationCircleFilled className="operator-only-informational__header__icon" />
                <Text className="operator-only-informational__header__text">
                    Message visible to the operator only, customers cannot see
                    it.
                </Text>
            </Space>
            <Text>{text}</Text>
        </Space>
    );
};

export default OperatorOnlyInformationalChatBubble;
