import "./UnavailableView.less";
import { Button, Typography, Space } from "antd";
const { Text } = Typography;
import withSessionReset from "../WithSessionReset/WithSessionReset";
import withChatHubDisconnect from "../WithChatHubDisconnect/WithChatHubDisconnect";
import {
    faqLink,
    helpEmail,
    faqButtonText,
    showHelpEmailButton,
} from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { customerServiceDeptName } from "@Src/tenantConfiguration/SharedTenantConfiguration";

const UnavailableView: React.FC = () => {
    return (
        <div className="unavailable-view">
            <div className="unavailable-view__content">
                <Text>
                    Our {customerServiceDeptName} team is currently offline and
                    unavailable to take your chat at this time. Please review
                    our Frequently Asked Questions or email our team if
                    necessary.
                </Text>
                <Space className="unavailable-view__content__buttons">
                    {faqLink && (
                        <Button type="primary" href={faqLink} target="_blank">
                            {faqButtonText}
                        </Button>
                    )}
                    {showHelpEmailButton && (
                        <Button
                            type="primary"
                            href={`mailto:${helpEmail}`}
                            target="_blank"
                        >
                            Email {customerServiceDeptName}
                        </Button>
                    )}
                </Space>
            </div>
        </div>
    );
};

export default withChatHubDisconnect(withSessionReset(UnavailableView));
