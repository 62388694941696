import { BaseMessage } from "@Hubs/chat/dtos/BaseMessage";
import { TransferContext } from "@Models/customer/TransferContext";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { UserJoinedChatMessage } from "@Src/hubs/chat/dtos/UserJoinedChatMessage";
import { UserLeftChatMessage } from "@Src/hubs/chat/dtos/UserLeftChatMessage";
import { UserTransferredFromBotToRepMessage } from "@Src/hubs/chat/dtos/UserTransferredFromBotToRepMessage";

import { BotTextMessage } from "@Src/hubs/chat/dtos/BotTextMessage";
import { ImageMessage } from "@Src/hubs/chat/dtos/ImageMessage";

import { SmartIncludeMessage } from "@Src/hubs/chat/dtos/SmartIncludeMessage";
import { TextMessage } from "@Src/hubs/chat/dtos/TextMessage";

import { UserTimedOutMessage } from "@Src/hubs/chat/dtos/UserTimedOutMessage";
import {
    getNonNullDelayMilliseconds,
    onNewOperatorLeftMessage,
} from "../services/customerApp/OperatorLeftDelayService";

export function mergeMessages(
    stateMessages?: BaseMessage[],
    backfillMessages?: BaseMessage[]
): BaseMessage[] {
    stateMessages = stateMessages ?? [];
    backfillMessages = backfillMessages ?? [];

    let stateIndex = 0;
    let backfillIndex = 0;
    const nextMessages = new Array<BaseMessage>();
    const encounteredMessageIds = new Set<string>();
    // Merge the existing messages and the backfill
    while (
        stateIndex < stateMessages.length &&
        backfillIndex < backfillMessages.length
    ) {
        const stateMessage = stateMessages[stateIndex];
        const backfillMessage = backfillMessages[backfillIndex];
        if (encounteredMessageIds.has(stateMessage.id)) {
            stateIndex++;
            continue;
        }
        if (encounteredMessageIds.has(backfillMessage.id)) {
            backfillIndex++;
            continue;
        }
        if (new Date(stateMessage.timestamp) < backfillMessage.timestamp) {
            nextMessages.push(stateMessage);
            encounteredMessageIds.add(stateMessage.id);
            stateIndex++;
        } else if (stateMessage.id !== backfillMessage.id) {
            nextMessages.push(backfillMessage);
            encounteredMessageIds.add(backfillMessage.id);
            backfillIndex++;
        } else {
            nextMessages.push(stateMessage);
            encounteredMessageIds.add(stateMessage.id);
            stateIndex++;
            backfillIndex++;
        }
    }
    for (; stateIndex < stateMessages.length; stateIndex++) {
        if (!encounteredMessageIds.has(stateMessages[stateIndex].id)) {
            nextMessages.push(stateMessages[stateIndex]);
        }
    }
    for (; backfillIndex < backfillMessages.length; backfillIndex++) {
        if (!encounteredMessageIds.has(backfillMessages[backfillIndex].id)) {
            nextMessages.push(backfillMessages[backfillIndex]);
        }
    }
    return nextMessages;
}

// This function removes the following messages from the message list:
// - Joined Left Messages during deploy (operator or customer)
// - Operator Joined/Left Messages that are very close (trying to find ones caused by refreshes)
// - non unique messages
// - Operator Only Informational Messages
export function suppressMessagesForCustomer(
    messages: BaseMessage[],
    onlyCheckLatest: boolean,
    applicationSettingsDelaySeconds: number | undefined
): BaseMessage[] {
    const lastEventMessageByOperator: {
        [senderUserId: string]:
            | UserLeftChatMessage
            | UserJoinedChatMessage
            | undefined;
    } = {};
    const messageIdsToIgnore = new Set<string>();
    const existingMessageIds: { [id: string]: boolean } = {};

    const messagesToCheck = getMessagesToCheck(messages, onlyCheckLatest);

    messagesToCheck.forEach((message) => {
        if (shouldSuppressWithSharedLogic(message)) {
            messageIdsToIgnore.add(message.id);
        }
        existingMessageIds[message.id] = true;

        //removes operator refresh/quick leave join type behavior
        if (message.clientRole === ClientRole.Operator) {
            const lastEventMessage =
                lastEventMessageByOperator[message.senderUserId];
            if (message.messageType === PersistedMessageType.UserLeftChat) {
                //two latest operator messages are left
                if (
                    lastEventMessage?.messageType ==
                    PersistedMessageType.UserLeftChat
                ) {
                    messageIdsToIgnore.add(lastEventMessage.id);
                }

                lastEventMessageByOperator[message.senderUserId] = message;
            } else if (
                message.messageType === PersistedMessageType.UserJoinedChat
            ) {
                if (
                    lastEventMessage?.messageType ==
                        PersistedMessageType.UserLeftChat &&
                    message.timestamp.getTime() -
                        lastEventMessage.timestamp.getTime() <
                        getNonNullDelayMilliseconds(
                            applicationSettingsDelaySeconds
                        )
                ) {
                    messageIdsToIgnore.add(lastEventMessage.id);
                    messageIdsToIgnore.add(message.id);
                } else if (
                    lastEventMessage?.messageType ==
                    PersistedMessageType.UserJoinedChat
                ) {
                    messageIdsToIgnore.add(lastEventMessage.id);
                }

                lastEventMessageByOperator[message.senderUserId] = message;
            } else if (
                message.messageType ===
                PersistedMessageType.OperatorOnlyInformationalMessage
            ) {
                // suppress operator only informational messages
                messageIdsToIgnore.add(message.id);
            }
        }
    });

    Object.keys(lastEventMessageByOperator).forEach((senderUserId) => {
        const lastEventMessage = lastEventMessageByOperator[senderUserId];
        if (
            lastEventMessage &&
            lastEventMessage.messageType ===
                PersistedMessageType.UserLeftChat &&
            lastEventMessage.timestamp.getTime() - Date.now() <
                getNonNullDelayMilliseconds(applicationSettingsDelaySeconds)
        ) {
            // If the last event message received by this operator was them leaving and it's within the delay window,
            // then assume we shouldn't show this yet. We will later get a join message OR the timeout will show it.
            onNewOperatorLeftMessage(lastEventMessage);
            messageIdsToIgnore.add(lastEventMessage.id);
        }
    });

    return messages.filter((message) => !messageIdsToIgnore.has(message.id));
}

// This function removes the following messages from the message list:
// - Joined Left Messages during deploy
// - User transferred from bot to rep from chat
// - Non unique messages
export function suppressMessagesForOperator(
    messages: BaseMessage[],
    onlyCheckLatest: boolean
): BaseMessage[] {
    const messageIdsToIgnore = new Set<string>();
    const existingMessageIds: { [id: string]: boolean } = {};

    const messagesToCheck = getMessagesToCheck(messages, onlyCheckLatest);

    messagesToCheck.forEach((message) => {
        if (shouldSuppressWithSharedLogic(message, existingMessageIds)) {
            messageIdsToIgnore.add(message.id);
        }
        existingMessageIds[message.id] = true;

        //operators hide certain types of UserTransferredFromBotToRep messages
        if (shouldHideBecauseTransferFromBotToRep(message)) {
            messageIdsToIgnore.add(message.id);
        }
    });

    return messages.filter((message) => !messageIdsToIgnore.has(message.id));
}

function shouldSuppressWithSharedLogic(
    message: BaseMessage,
    existingMessageIds: { [id: string]: boolean } = {}
): boolean {
    //isn't unique or is a join / leave message during a deploy
    return (
        existingMessageIds[message.id] || shouldHideBecauseDuringDeploy(message)
    );
}

function shouldHideBecauseDuringDeploy(message: BaseMessage): boolean {
    // Joined Left Messages during deploy
    return (
        (message.messageType === PersistedMessageType.UserJoinedChat ||
            message.messageType === PersistedMessageType.UserLeftChat) &&
        (message as UserJoinedChatMessage).shouldHide
    );
}

function shouldHideBecauseTransferFromBotToRep(message: BaseMessage): boolean {
    if (
        message.messageType !== PersistedMessageType.UserTransferredFromBotToRep
    ) {
        return false;
    }

    const transferMessage = message as UserTransferredFromBotToRepMessage;
    return (
        transferMessage.transferContext !== TransferContext.TransferFromFeedback
    );
}

function getMessagesToCheck(
    messages: BaseMessage[],
    onlyCheckLatest: boolean
): BaseMessage[] {
    return onlyCheckLatest ? [messages[messages.length - 1]] : messages;
}

// Function was moved from ChatApiShared.ts as it was needed in other files
// This function converts the messages to their concrete types so they can be displayed correctly with associated buisness logic
// When casting to BaseMessage business logic is not run and the message is not displayed correctly
export function ConvertToConcreteMessages(
    messages?: BaseMessage[]
): BaseMessage[] {
    if (messages) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return messages.map((message: any) => {
            message.timestamp = new Date(message.timestamp);
            switch (message.messageType) {
                case PersistedMessageType.BotText:
                    return new BotTextMessage(message);
                case PersistedMessageType.Image:
                    return new ImageMessage(message);
                case PersistedMessageType.Text:
                    return new TextMessage(message);
                case PersistedMessageType.SmartInclude:
                    return new SmartIncludeMessage(message);
                case PersistedMessageType.UserJoinedChat:
                    return new UserJoinedChatMessage(message);
                case PersistedMessageType.UserLeftChat:
                    return new UserLeftChatMessage(message);
                case PersistedMessageType.UserTimedOut:
                    return new UserTimedOutMessage(message);
                default:
                    return message;
            }
        });
    }
    return [];
}
