import React from "react";
import { Space, Typography, List, Collapse } from "antd";

import "./ResolutionList.less";
import EstimatedTotalToolTip from "../ToolTips/EstimatedTotalToolTip";

const { Text } = Typography;
const { Panel } = Collapse;

const ResolutionList = (props: any): React.JSX.Element => {
    const {
        resolutionList,
        totalPrice,
        isReadOnly,
        readOnlyFooterDetail,
        resolutionListRenderItem,
        additionalFooter,
        allowCollapse,
        collapsePanelHeader,
        keyValue,
    } = props;

    const footer = (
        <>
            <Space
                size={0}
                className={`${
                    additionalFooter ? "suppress-bottom-padding" : ""
                }`}
            >
                {!isReadOnly && (
                    <>
                        <EstimatedTotalToolTip />
                        <Text className="estimated-header-padding">
                            Estimated Total
                        </Text>
                        <Text strong>${totalPrice.toFixed(2)}</Text>
                    </>
                )}
                {isReadOnly && readOnlyFooterDetail}
            </Space>
            {additionalFooter?.()}
        </>
    );

    return (
        <>
            {allowCollapse && (
                <div className="item-resolution-list">
                    <Collapse defaultActiveKey={["0"]}>
                        <Panel header={collapsePanelHeader} key="1">
                            <List
                                className="item-resolution-list"
                                itemLayout="vertical"
                                pagination={
                                    resolutionList.length > 5 && {
                                        showTotal: (total, range) =>
                                            `${range[0]}-${range[1]} of ${total} items`,
                                        pageSize: 5,
                                        total: resolutionList.length,
                                        size: "small",
                                        showSizeChanger: false,
                                    }
                                }
                                dataSource={resolutionList}
                                renderItem={resolutionListRenderItem}
                                key={keyValue}
                            />
                        </Panel>
                    </Collapse>
                    <div className="ant-list-footer">{footer}</div>
                </div>
            )}
            {!allowCollapse && (
                <List
                    className="item-resolution-list"
                    itemLayout="vertical"
                    pagination={
                        resolutionList.length > 5 && {
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                            pageSize: 5,
                            total: resolutionList.length,
                            size: "small",
                        }
                    }
                    dataSource={resolutionList}
                    renderItem={resolutionListRenderItem}
                    footer={footer}
                />
            )}
        </>
    );
};

export default ResolutionList;
