export enum ChatHubEvents {
    ReceiveTextMessage = "ReceiveTextMessage",
    ReceiveBotTextMessage = "ReceiveBotTextMessage",
    ReceiveUserJoinedChatMessage = "ReceiveUserJoinedChatMessage",
    ReceiveUserLeftChatMessage = "ReceiveUserLeftChatMessage",
    ReceiveImageMessage = "ReceiveImageMessage",
    ReceiveJoinChat = "ReceiveJoinChat",
    ReceiveChatWasClosed = "ReceiveChatWasClosed",
    ReceiveSmartIncludeMessage = "ReceiveSmartIncludeMessage",
    ReceiveMessageToDelete = "ReceiveMessageToDelete",
    ReceiveUserTimedOutMessage = "ReceiveUserTimedOutMessage",
    ReceiveMyServerDeployStarted = "ReceiveMyServerDeployStarted",
    ReceiveTypingIndicator = "ReceiveTypingIndicator",
    ReceiveStopTypingIndicator = "ReceiveStopTypingIndicator",
    ReceiveCustomerEndedChat = "ReceiveCustomerEndedChat",
    ReceiveCustomActivityMessage = "ReceiveCustomActivityMessage",
    UpdateMessageJsonData = "UpdateMessageJsonData",
    ReceiveOperatorsOnlineChangeMessage = "ReceiveOperatorsOnlineChangeMessage",
    ReceiveOperatorOnlyInformationalMessage = "ReceiveOperatorOnlyInformationalMessage",
}
