import "./Message.less";
import "../ChatBubbles/ChatBubble.less";
import { useState } from "react";
import { Avatar, Spin } from "antd";
import {
    ExclamationCircleFilled,
    Loading3QuartersOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import classNames from "classnames";
import { SmartIncludeMessage } from "@Src/hubs/chat/dtos/SmartIncludeMessage";
import { TextMessage } from "@Hubs/chat/dtos/TextMessage";
import { FailedTextMessage } from "@Models/chat/FailedTextMessage";
import { BotTextMessage } from "@Hubs/chat/dtos/BotTextMessage";
import { ImageMessage } from "@Hubs/chat/dtos/ImageMessage";
import { BaseMessage } from "@Hubs/chat/dtos/BaseMessage";
import { PersistedMessage } from "@Hubs/chat/dtos/PersistedMessage";
import { OperatorOnlyInformationalMessage } from "@Hubs/chat/dtos/OperatorOnlyInformationalMessage";
import {
    PersistedMessageType,
    isBotCustomActivity,
} from "@Hubs/chat/dtos/PersistedMessageType";
import { ClientMessageType } from "@Hubs/chat/dtos/ClientMessageType";
import { ClientMessage } from "@Src/hubs/chat/dtos/ClientMessage";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";
import { MessageApiShared } from "@Api/MessageApiShared";
import {
    isContentType,
    isEventType,
} from "@Hubs/chat/dtos/RenderedMessageType";
import ResendButton from "./ResendButton";
import SmartOrderLink from "@Components/shared/SmartOrderLink/SmartOrderLink";
import TextChatBubble from "@Components/shared/ChatBubbles/TextChatBubble/TextChatBubble";
import FailedChatBubble from "@Components/shared/ChatBubbles/FailedChatBubble/FailedChatBubble";
import BotChatBubble from "@Components/shared/ChatBubbles/BotChatBubble/BotChatBubble";
import ImageChatBubble from "@Components/shared/ChatBubbles/ImageChatBubble/ImageChatBubble";
import SmartIncludeChatBubble from "@Components/shared/ChatBubbles/SmartIncludeChatBubble/SmartIncludeChatBubble";
import OperatorOnlyInformationalChatBubble from "../ChatBubbles/OperatorOnlyInformationalChatBubble/OperatorOnlyInformationalChatBubble";
import ChatInfo from "@Components/shared/ChatInfo/ChatInfo";
import SmartZipCodeLookup from "../SmartZipCodeLookup/SmartZipCodeLookup";
import {
    customerServiceDeptName,
    tenantRoute,
} from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { TenantRoute } from "@Models/TenantRoute";
import botChatImage from "@Images/pepper-avatar.svg";
import { UserTransferredFromBotToRepMessage } from "@Src/hubs/chat/dtos/UserTransferredFromBotToRepMessage";
import { TransferContext } from "@Models/customer/TransferContext";
import { UserLeftChatMessage } from "@Src/hubs/chat/dtos/UserLeftChatMessage";
import { TrackOrderDataGroupedByStatus } from "@Components/CustomerApp/BotConversationView/CustomActivities/TrackOrderDataGroupedByStatus";
import TrackOrderTextBubble from "@Components/CustomerApp/BotConversationView/CustomActivities/TrackOrderTextBubble";
import TaxFormBubble from "@Components/CustomerApp/BotConversationView/CustomActivities/TaxFormBubble";
import { TaxExemption } from "@Models/account/TaxExemption";
import { CustomActivityMessage } from "@Src/hubs/chat/dtos/CustomActivityMessage";
import { TaxFormBubbleWrapper } from "@Components/CustomerApp/BotConversationView/CustomActivities/TaxFormBubbleWrapper";
import LiveChatItemRefundReship from "@Components/CustomerApp/BotConversationView/CustomActivities/ItemIssues/RefundReship/LiveChatItemRefundReship";
import LiveChatItemReturns from "@Components/CustomerApp/BotConversationView/CustomActivities/ItemIssues/Returns/LiveChatItemReturns";
import LiveChatItemFoodQualityIssue from "@Components/CustomerApp/BotConversationView/CustomActivities/ItemIssues/FoodQualityIssue/LiveChatItemFoodQualityIssue";

interface MessageProps {
    message: BaseMessage;
    isMineOrOperatorSeeingBot: boolean;
    isFirstConsecutive: boolean;
    isLastConsecutive: boolean;
    sendMessageAsUser(message: PersistedMessage): Promise<string>;
    isChatHubConnected: boolean;
    smartIncludesEnabled: boolean;
    messageApi: MessageApiShared;
    avatar: string | null;
    baseImageRoute: string;
    onRerenderNeeded?: () => void;
}

const Message = ({
    message,
    isMineOrOperatorSeeingBot,
    isFirstConsecutive,
    isLastConsecutive,
    sendMessageAsUser,
    isChatHubConnected,
    smartIncludesEnabled,
    messageApi,
    avatar,
    baseImageRoute,
    onRerenderNeeded,
}: MessageProps): JSX.Element | null => {
    const isConversationContent = isContentType(message.messageType);

    const isConversationEvent = isEventType(message.messageType);

    const isAvatarVisible =
        !isMineOrOperatorSeeingBot &&
        isFirstConsecutive &&
        isConversationContent;

    const isEnvelopeInfoVisible =
        isConversationContent &&
        isLastConsecutive &&
        message.messageType != ClientMessageType.FailedText;

    const [isResending, setIsResending] = useState<boolean>(false);

    const getAvatar = (): JSX.Element => {
        return (
            <>
                {message.messageType === PersistedMessageType.BotText ||
                isBotCustomActivity(message.messageType) ? (
                    <Avatar size={80} className="avatar" src={botChatImage} />
                ) : (
                    <Avatar size={80} className="avatar" src={avatar}>
                        {message.senderName.slice(0, 2)}
                    </Avatar>
                )}
            </>
        );
    };

    const shortenSenderName = (name: string): string => {
        if (name.length > 28) return name.substring(0, 25) + "...";
        return name;
    };

    const getEnvelopeInfo = (): JSX.Element => (
        <div className="envelope-info">
            <span className="sender-name">{message.senderName}</span>
            <span className="timestamp">
                {dayjs(message.timestamp).format("hh:mm:ss A")}
            </span>
        </div>
    );

    const getFirstMatchOrNull = (
        text: string,
        regex: RegExp
    ): string | null => {
        const matches = Array.from(text.matchAll(regex), (match) => match[1]);
        return matches.length > 0 ? matches[0] : null;
    };

    const getSmartOrderLink = (text: string): JSX.Element | undefined => {
        const containsEightOrNineDigits = /(?:\D|^)(\d{8,9})(?!\d)/g;

        const orderNumber = getFirstMatchOrNull(
            text,
            containsEightOrNineDigits
        );

        return orderNumber ? (
            <SmartOrderLink
                orderNumber={orderNumber}
                isMineOrOperatorSeeingBot={isMineOrOperatorSeeingBot}
            />
        ) : undefined;
    };

    const getZipCodeLookup = (text: string): JSX.Element | undefined => {
        const containsNineDigitZipCode = /(?:\D|^)(\d{5}\s*-\s*\d{4})(?!\d)/g;
        const containsFiveDigitZipCode = /(?:\D|^)(\d{5})(?:\s|$)/g;

        let zipCode = getFirstMatchOrNull(text, containsNineDigitZipCode);

        if (zipCode) {
            zipCode = zipCode.split("-")[0].trim();
        } else {
            zipCode = getFirstMatchOrNull(text, containsFiveDigitZipCode);
        }

        return zipCode ? (
            <SmartZipCodeLookup
                zipCode={zipCode}
                isMineOrOperatorSeeingBot={isMineOrOperatorSeeingBot}
            />
        ) : undefined;
    };

    const getMessageBody = (): JSX.Element | undefined => {
        if (isConversationContent) {
            switch (message.messageType) {
                case PersistedMessageType.Text: {
                    const textMessage = message as TextMessage;
                    return (
                        <TextChatBubble
                            text={textMessage.text}
                            textOrLinkArray={textMessage.textOrLinkArray}
                            senderRole={message.clientRole}
                            smartOrderLink={
                                smartIncludesEnabled
                                    ? getSmartOrderLink(textMessage.text)
                                    : undefined
                            }
                            smartZipCodeLookup={
                                tenantRoute === TenantRoute.wss &&
                                smartIncludesEnabled
                                    ? getZipCodeLookup(textMessage.text)
                                    : undefined
                            }
                        />
                    );
                }
                case ClientMessageType.FailedText: {
                    const failedMessage = message as FailedTextMessage;
                    return (
                        <>
                            <span>
                                <FailedChatBubble text={failedMessage.text} />
                                {isResending ? (
                                    <div className="delivery-status resending">
                                        Resending message...
                                    </div>
                                ) : (
                                    <div className="delivery-status failed">
                                        <ExclamationCircleFilled
                                            style={{ fontSize: "12px" }}
                                        />
                                        Message Not Sent!
                                        {isChatHubConnected && (
                                            <ResendButton
                                                message={message}
                                                sendMessageAsUser={
                                                    sendMessageAsUser
                                                }
                                                setIsResending={setIsResending}
                                            />
                                        )}
                                    </div>
                                )}
                            </span>
                            <Spin
                                spinning={isResending}
                                indicator={
                                    <Loading3QuartersOutlined
                                        style={{ fontSize: 28 }}
                                        spin
                                    />
                                }
                            />
                        </>
                    );
                }
                case PersistedMessageType.BotText: {
                    const botTextMessage = message as BotTextMessage;
                    return (
                        <BotChatBubble
                            text={botTextMessage.text}
                            isBotChoice={botTextMessage.isBotChoice}
                        />
                    );
                }
                case PersistedMessageType.Image: {
                    const imageMessage = message as ImageMessage;
                    return (
                        <ImageChatBubble
                            imageUrl={imageMessage.url}
                            fileName={imageMessage.fileName}
                            chatId={imageMessage.chatId}
                            messageId={imageMessage.id}
                            messageApi={messageApi}
                            baseImageRoute={baseImageRoute}
                            isContentInappropriate={
                                imageMessage.isContentInappropriate
                            }
                            onImageLoaded={onRerenderNeeded}
                        />
                    );
                }
                case ClientMessageType.SmartInclude: {
                    const smartIncludeMessage = message as SmartIncludeMessage;
                    return (
                        <SmartIncludeChatBubble
                            textOrLinkArray={
                                smartIncludeMessage.textOrLinkArray
                            }
                            senderRole={message.clientRole}
                            url={smartIncludeMessage.pageUrl}
                            image={smartIncludeMessage.pageImage}
                            title={smartIncludeMessage.pageTitle}
                            description={smartIncludeMessage.pageDescription}
                        />
                    );
                }
                case PersistedMessageType.OrderTracking: {
                    const orderTrackingMessage =
                        message as CustomActivityMessage;
                    const data = JSON.parse(
                        orderTrackingMessage.jsonData
                    ) as TrackOrderDataGroupedByStatus;
                    return <TrackOrderTextBubble data={data} />;
                }
                case PersistedMessageType.TaxExemptionStatus: {
                    const TaxExemptionStatusMessage =
                        message as CustomActivityMessage;
                    const data = JSON.parse(
                        TaxExemptionStatusMessage.jsonData
                    ) as TaxExemption[];
                    return (
                        <TaxFormBubbleWrapper>
                            <TaxFormBubble
                                id={message.id}
                                taxData={data}
                                isForScreenReader={false}
                            />
                        </TaxFormBubbleWrapper>
                    );
                }
                case PersistedMessageType.RefundReship: {
                    const refundReshipMessage =
                        message as CustomActivityMessage;
                    const data = JSON.parse(refundReshipMessage.jsonData);
                    return (
                        <LiveChatItemRefundReship
                            resolutionsInfo={data.itemRefundReshipResolution}
                            isCanceled={data.isCanceled}
                            chatId={message.chatId}
                            messageId={message.id}
                            orderInfo={data.itemRefundReship}
                        />
                    );
                }
                case PersistedMessageType.Returns: {
                    const returnMessage = message as CustomActivityMessage;
                    const data = JSON.parse(returnMessage.jsonData);
                    return (
                        <LiveChatItemReturns
                            resolutionsInfo={data.itemReturnsResolutions}
                            isCanceled={data.isCanceled}
                            itemNotListed={data.itemNotListed}
                            chatId={message.chatId}
                            messageId={message.id}
                            orderInfo={data.itemReturns}
                            validReturnReasons={data.validReturnReasons}
                            restockingFeePercentage={
                                data.restockingFeePercentage
                            }
                        />
                    );
                }
                case PersistedMessageType.FoodQualityIssue: {
                    const foodQualityIssueMessage =
                        message as CustomActivityMessage;
                    const data = JSON.parse(foodQualityIssueMessage.jsonData);
                    return (
                        <LiveChatItemFoodQualityIssue
                            chatId={message.chatId}
                            orderInfo={data.itemFoodQualityIssue}
                            messageId={message.id}
                            resolutionsInfo={
                                data.itemFoodQualityIssueResolution
                            }
                            eligibleFoodQualityItemIssueNames={
                                data.eligibleFoodQualityItemIssueNames
                            }
                            isCanceled={data.isCanceled}
                        />
                    );
                }
                case PersistedMessageType.OperatorOnlyInformationalMessage: {
                    const operatorOnlyMessage =
                        message as OperatorOnlyInformationalMessage;
                    return (
                        <OperatorOnlyInformationalChatBubble
                            text={operatorOnlyMessage.text}
                        />
                    );
                }
                default:
                    return (
                        <TextChatBubble
                            text={"Default conversation content."}
                            textOrLinkArray={[
                                {
                                    text: "Default conversation content.",
                                    isLink: false,
                                    isApprovedDomain: false,
                                },
                            ]}
                            senderRole={message.clientRole}
                        />
                    );
            }
        }

        if (isConversationEvent) {
            const senderName = shortenSenderName(message.senderName);
            switch (message.messageType) {
                case ClientMessageType.ChatCreated: {
                    return (
                        <ChatInfo
                            // eslint-disable-next-line prettier/prettier
                            text={`Welcome ${senderName}! Your request has been directed to our ${customerServiceDeptName} department.
                    Please wait for a team member to answer your chat.`}
                        />
                    );
                }
                case PersistedMessageType.UserJoinedChat: {
                    return <ChatInfo text={`${senderName} joined the chat.`} />;
                }
                case PersistedMessageType.UserLeftChat: {
                    const leftMessage = message as UserLeftChatMessage;
                    if (leftMessage.endChatButtonPressed) {
                        return (
                            <ChatInfo text={`${senderName} ended the chat.`} />
                        );
                    }

                    return <ChatInfo text={`${senderName} left the chat.`} />;
                }
                case PersistedMessageType.UserTransferredFromBotToRep: {
                    const transferMessage =
                        message as UserTransferredFromBotToRepMessage;

                    if (
                        transferMessage.transferContext ===
                        TransferContext.TransferFromFeedback
                    ) {
                        return (
                            <ChatInfo
                                text={`${senderName} requested to speak to a ${customerServiceDeptName} Specialist from the feedback screen.`}
                            />
                        );
                    }
                    break;
                }
                case PersistedMessageType.UserTimedOut: {
                    return (
                        <ChatInfo
                            text={`${message.senderName} has timed out.`}
                        />
                    );
                }
                case ClientMessageType.Reconnecting: {
                    const reconnectMessage = message as ClientMessage;

                    if (reconnectMessage.clientRole === ClientRole.Operator) {
                        return <ChatInfo text={"Attempting to reconnect..."} />;
                    } else {
                        return (
                            <ChatInfo
                                text={`A connection error has occurred, you will be reconnected automatically.
                                If the problem persists try to `}
                                link={
                                    <a
                                        href={window.location.href}
                                        onClick={(): void =>
                                            window.location.reload()
                                        }
                                    >
                                        Refresh.
                                    </a>
                                }
                            />
                        );
                    }
                }
                default:
                    return <></>;
            }
        }

        return <></>;
    };

    return (
        <div
            key={message.id}
            className={classNames({
                message: true,
                content: isConversationContent,
                "message-from-me-or-operator-seeing-bot":
                    isMineOrOperatorSeeingBot,
                "message-from-them": !isMineOrOperatorSeeingBot,
                "first-consecutive": isFirstConsecutive,
                "last-consecutive": isLastConsecutive,
                "failed-message":
                    message.messageType === ClientMessageType.FailedText,
            })}
        >
            {isAvatarVisible && getAvatar()}
            {getMessageBody()}
            {isEnvelopeInfoVisible && getEnvelopeInfo()}
        </div>
    );
};

export default Message;
